<template>
    <div class="add-container">
        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="150"
            label-position="right"
        >
            <Form-item prop="name" label="店铺名称">
                <Input v-model="formItem.name" placeholder="请输入店铺名称" />
            </Form-item>

            <Form-item prop="tese" label="店铺特色">
                <Input v-model="formItem.tese" type="textarea" :rows="3" placeholder="请输入店铺特色（例如：啤酒，烧鸡）" />
            </Form-item>

            <Form-item prop="abstract" label="店铺描述">
                <Input v-model="formItem.abstract" type="textarea" :rows="3" placeholder="请输入店铺描述" />
            </Form-item>

            <Form-item prop="logo" label="店铺封面">
                <CUpload
                    :default-list="formItem.logo"
                    :maxLength="5"
                    width="150"
                    height="90"
                    :size="700"
                    sizeTip
                    :ratio="[750, 450]"
                    @change="onChange($event, 'logo')"
                />
            </Form-item>

            <Form-item prop="type" label="一级分类">
                <Select
                    v-model="formItem.type"
                    placeholder="店铺一级分类"
                    filterable
                    multiple
                >
                    <Option
                        v-for="item in typeList"
                        :value="item.name"
                        :key="item.id"
                    >{{ item.name }}</Option>
                </Select>
            </Form-item>

            <Form-item prop="secNav" label="二级分类">
                <Select
                    v-model="formItem.secNav"
                    placeholder="店铺二级分类"
                    filterable
                    multiple
                >
                    <Option
                        v-for="item in secNavList"
                        :value="item.name"
                        :key="item.id"
                    >{{ item.name }}</Option>
                </Select>
            </Form-item>

            <Form-item prop="sort" label="排序">
                <Input
                    v-model.number="formItem.sort"
                    type="number"
                    placeholder="请输入排序值"
                />
            </Form-item>

            <Form-item label="店铺收银小程序码">
                <div v-if="qrcodeUrl" class="qrcode-box">
                    <img class="ewm" :src="qrcodeUrl" />
                    <Button
                        class="qrcode-submit"
                        type="primary"
                        @click="downloadEwm"
                    >
                        点击下载
                    </Button>
                    <!-- <canvas ref="canvas" id="myCanvas" width="430" height="600"></canvas> -->
                </div>
            </Form-item>

            <!-- <Form-item prop="yongjin" label="佣金比例">
                <Input
                    v-model.number="formItem.yongjin"
                    class="add-100"
                    type="number"
                    placeholder="请输入佣金比例"
                /> %
            </Form-item>
            <Form-item prop="discount" label="折扣">
                <Input
                    v-model.number="formItem.discount"
                    class="add-100"
                    type="number"
                    placeholder="请输入折扣"
                /> 折
            </Form-item> -->
            <!-- <Form-item prop="yongjin" label="佣金比例">
                <Input
                    v-model="formItem.yongjin"
                    class="add-120"
                    placeholder="5"
                    search
                    type="number"
                    enter-button="%"
                />
            </Form-item> -->

            <Form-item prop="discount" label="店铺折扣">
                <Input
                    v-model="formItem.discount"
                    class="add-120"
                    placeholder="8.5"
                    search
                    type="number"
                    enter-button="折"
                />
            </Form-item>

            <!-- <Form-item prop="classStore" label="店铺抽成类型">
                <RadioGroup v-model="formItem.classStore">
                    <Radio label="1" border>A类店铺</Radio>
                    <Radio label="2" border>B类店铺</Radio>
                </RadioGroup>
            </Form-item> -->

            <!-- <Form-item prop="classStoreId" label="店铺抽成类型">
                <RadioGroup v-model="formItem.classStoreId">
                    <Radio
                        v-for="item in classStoreList"
                        :label="item.id"
                        border
                        disabled
                        :key="item.id"
                    >{{ item.title }}</Radio>
                </RadioGroup>
            </Form-item>

            <Form-item prop="qianggouClassStoreId" label="抢购商品抽成类型">
                <RadioGroup v-model="formItem.qianggouClassStoreId">
                    <Radio
                        v-for="item in classStoreList"
                        :label="item.id"
                        border
                        disabled
                        :key="item.id"
                    >{{ item.title }}</Radio>
                </RadioGroup>
            </Form-item> -->

            <Form-item class="zindex3" prop="tuijianyuanNumber" label="推荐员">
                <Select
                    v-model="formItem.tuijianyuanNumber"
                    class="add-210 tuijianren-openid"
                    placeholder="选择推荐人"
                    filterable
                    clearable
                    :disabled="isDisabled"
                >
                    <Option
                        v-for="item in tuijianyuanList"
                        :value="item.number"
                        :key="item.id"
                    >{{ item.nickname }}</Option>
                </Select>
                <template v-if="formItem.tuijianyuanNumber">
                    <span class="tjybh">推荐员编号</span> <Input v-model="formItem.tuijianyuanNumber" class="add-120" disabled />
                </template>
            </Form-item>

            <Form-item prop="showStore" label="是否显示店铺">
                <RadioGroup v-model="formItem.showStore">
                    <Radio class="add-radio" label="1">显示</Radio>
                    <Radio class="add-radio" label="0">隐藏</Radio>
                </RadioGroup>
            </Form-item>

            <Form-item prop="payPoster" label="支付封面图">
                <CUpload
                    :default-list="formItem.payPoster"
                    :maxLength="1"
                    :size="300"
                    sizeTip
                    @change="onChange($event, 'payPoster')"
                />
            </Form-item>

            <Form-item class="zindex2" prop="openid" label="绑定店长">
                <Select
                    v-model="formItem.openid"
                    class="add-210"
                    placeholder="绑定店长"
                    filterable
                    clearable
                >
                    <Option
                        v-for="item in openidList"
                        :value="item.openid"
                        :key="item.id"
                    >{{ item.nickname }}</Option>
                </Select>
            </Form-item>

            <Form-item prop="phone" label="联系方式">
                <Input
                    v-model="formItem.phone"
                    class="add-210"
                    placeholder="请输入店铺联系方式"
                />
            </Form-item>

            <Form-item prop="sn" label="语音播报设备">
                <Input
                    v-model="formItem.sn"
                    class="add-210"
                    placeholder="请输入语音播报sn码"
                />
            </Form-item>

            <Form-item prop="shareTitle" label="分享标题">
                <Input v-model="formItem.shareTitle" placeholder="请输入分享标题" />
            </Form-item>

            <Form-item prop="sharePic" label="分享图片">
                <CUpload
                    :default-list="formItem.sharePic"
                    :maxLength="5"
                    width="250"
                    height="200"
                    :size="200"
                    sizeTip
                    :ratio="[500, 400]"
                    @change="onChange($event, 'sharePic')"
                />
            </Form-item>

            <Form-item class="zindex1" prop="address" label="店铺地址">
                <Input
                    v-model="formItem.address"
                    placeholder="请在地图上搜索店铺地址"
                    @on-change="searchAddress"
                >
                    <span slot="prepend">地址</span>
                </Input>
                <div class="address-location">
                    <Input
                        v-model="formItem.location.lat"
                        class="address-location-input add-210"
                        disabled
                        placeholder="请输入坐标地址"
                    >
                        <span slot="prepend">坐标lat</span>
                    </Input>
                    <Input
                        v-model="formItem.location.lng"
                        class="address-location-input add-210"
                        disabled
                        placeholder="请输入坐标地址"
                    >
                        <span slot="prepend">坐标lng</span>
                    </Input>
                </div>
                <div class="address-map">
                    <div id="map"></div>
                </div>
            </Form-item>

            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import _ from 'lodash';
import CUpload from '@/components/upload/index';
import {drawImageAndText} from '@/utils/utils';

export default {
    name: 'StoreInfo',
    components: {
        CUpload
    },
    data() {
        const confirmLogo = (rule, value, callback) => {
            if (this.formItem.logo.length > 0) {
                callback();
            }
            else {
                callback(new Error('店铺logo不能为空'));
            }
        };

        const confirmType = (rule, value, callback) => {
            if (value.length > 0) {
                callback();
            }
            else {
                callback(new Error('请选择店铺类型'));
            }
        }

        // const confirmYongjin = (rule, value, callback) => {
        //     if (+value > 100 || +value <=0) {
        //         callback(new Error('店铺佣金比例需要在0-100之间'));
        //     }
        //     else {
        //         callback();
        //     }
        // };

        const confirmDiscount = (rule, value, callback) => {
            if (+value > 10 || +value <=0) {
                callback(new Error('店铺折扣比例需要在0-10之间'));
            }
            else {
                callback();
            }
        };

        const confirmAddress = (rule, value, callback) => {
            const {lat, lng} = this.formItem.location;
            if (!value) {
                callback(new Error('店铺地址不能为空'));
            }
            else if (!(lat && lng)) {
                callback(new Error('没有搜索到准确地址，请重新输入地址'));
            }
            else {
                callback();
            }
        }

        const validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入店铺联系方式'));
            }
            else {
                const isPhone = /^1[3456789]\d{9}$/;
                const isMob=/^((0\d{2,3})-)?(\d{7,8})$/;
                if (isMob.test(value) || isPhone.test(value)) {
                    callback();
                }
                else{
                    callback(new Error('联系方式格式错误！'));
                }
            }
        };

        // const validateClassStoreId = (rule, value, callback) => {
        //     if (value) {
        //         callback();
        //     }
        //     else {
        //         callback(new Error('请选择店铺抽成类型'));
        //     }
        // }

        // const validateqianggouClassStoreId = (rule, value, callback) => {
        //     if (value) {
        //         callback();
        //     }
        //     else {
        //         callback(new Error('请选择抢购商品抽成类型'));
        //     }
        // }

        return {
            formItem: {
                name: '',
                tese: '',
                abstract: '',
                logo: [],
                type: [],
                secNav: [],
                sort: 0,
                yongjin: '',
                discount: '',
                classStore: '1',
                // classStoreId: null,
                // qianggouClassStoreId: null,
                tuijianrenNumber: '',
                showStore: '1',
                address: '',
                phone: '',
                location: {
                    lat: '',
                    lng: ''
                },
                openid: '',
                sn: '',
                shareTitle: '',
                sahrePic: []
            },
            ruleValidate: {
                name: [
                    {required: true, message: '店铺名称不能为空', trigger: 'change'}
                ],
                logo: [
                    {required: true, validator: confirmLogo, trigger: 'blur'}
                ],
                type: [
                    {required: true, validator: confirmType, trigger: 'change'}
                ],
                // yongjin: [
                //     {required: true, validator: confirmYongjin, trigger: 'blur'}
                // ],
                discount: [
                    {required: true, validator: confirmDiscount, trigger: 'change'}
                ],
                openid: [
                    {required: true, message: '请绑定店长', trigger: 'change'}
                ],
                address: [
                    {required: true, validator: confirmAddress, trigger: 'change'}
                ],
                phone: [
                    {required: true, validator: validatePhone, trigger: 'blur'}
                ],
                // classStoreId: [
                //     {required: true, validator: validateClassStoreId, trigger: 'change'}
                // ],
                // qianggouClassStoreId: [
                //     {required: true, validator: validateqianggouClassStoreId, trigger: 'change'}
                // ]
            },
            cacheData: null,
            typeList: null,
            openidList: null,
            iTimer: null,
            qrcodeUrl: '',

            map: null,
            marker: null,
            key: 'RS3BZ-HC4KQ-VSH5N-4LOZT-AVKD7-QWBK4',
            isWatch: false,
            classStore: null,
            tuijianyuanList: [],
            isDisabled: false,
            secNavList: [],
            classStoreList: []
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            async handler(storeData) {
                if (Object.keys(storeData).length === 0) return;
                console.log('storeData', storeData);
                this.formItem = storeData;

                // 如果店铺绑定了推荐员则禁止以后更改
                if (this.formItem.tuijianyuanNumber) {
                    this.isDisabled = true;
                }

                const getTypeList = () => {
                    return this.$axios({
                        url: '/api/storeType/list',
                        method: 'post'
                    })
                }

                // 店铺抽成类型列表
                const getClassStore = () => {
                    return this.$axios({
                        url: '/api/setup/classStore/list',
                        method: 'post'
                    })
                }
                this.$axios.all([getTypeList(), getClassStore()]).then(res => {
                    if (res.length === 0) {
                        return;
                    }
                    this.typeList = res[0].data.list;
                    this.classStoreList = res[1].data.list;
                    console.log('this.classStoreList', this.classStoreList);
                }).catch(() => {});

                const QRcodeData = await this.$axios({
                    url: '/api/QRCode',
                    method: 'post',
                    data: {
                        path: 'pages/buy/buy',
                        scene: storeData.id
                    }
                });
                this.qrcodeUrl = QRcodeData.data.src;

                // 获取推荐员列表
                this.getTuijianyuanList();

                // 获取绑定店长
                await this.getShopOwner();

                // 初始化腾讯地图
                this.initMap();
            }
        },
        'formItem.type': {
            immediate: true,
            async handler(value, oldValue) {

                // 如果删除了某一级分类，要对已选中的二级分类中清除已删除的一级分类的下二级分类
                if (value && oldValue && oldValue.length > value.length) {
                    const secNav = await this.resetSecNav(value, oldValue, this.formItem.secNav);
                    console.log('newSecNav', secNav);
                    this.formItem.secNav = secNav.data.list;
                }

                // 获取二级分类
                const secNavList = await this.getSecNavList(value);
                this.secNavList = secNavList.data.list;
            }
        }
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/store/addStore',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        onChange(value, name) {
            this.formItem[name] = value;
        },

        // 获取所有授权当前小程序的微信用户列表
        getShopOwner(params = {}) {
            this.$axios({
                url: '/api/store/getShopOwner',
                method: 'post',
                data: params
            }).then(res => {
                this.openidList = res.data.list;
            }).catch(() => {});
        },
        getTuijianyuanList() {
            this.$axios({
                url: '/api/tuijianyuan/list',
                method: 'post'
            }).then(res => {
                console.log('res2', res);
                this.tuijianyuanList = res.data.list;
                console.log('tuijianyuanList', tuijianyuanList);
            }).catch(() => {});
        },
        downloadEwm() {
            drawImageAndText(this.qrcodeUrl, this.getStoreData.name);
        },
        searchAddress() {
            this.$axios({
                url: '/tx/ws/geocoder/v1/',
                params: {
                    key: this.key,
                    address: this.formItem.address
                }
            }).then(res => {
                if (res.data.status !== 0) {
                    this.formItem.location = {
                        lat: '',
                        lng: ''
                    }
                }
                else {
                    const {lat = '', lng = ''} = res.data.result.location;
                    this.formItem.location = {
                        lat,
                        lng
                    }
                    this.map.setCenter(new TMap.LatLng(lat, lng));
                    this.setMarker(lat, lng);
                }
            }).catch(cat => {
                console.log('cat', cat);
            });
        },
        initMap() {
            const mapDom = document.getElementById('map');
            if (!mapDom) {
                return;
            }
            const {lat, lng} = this.formItem.location;
            this.map = new TMap.Map(mapDom, {
                center: new TMap.LatLng(lat, lng),
                zoom: 14
            });
            this.setMarker(lat, lng);

            // 监听点击地图获取坐标
            if (!this.isWatch) {
                this.map.on("click", evt => {
                    const lat = evt.latLng.getLat().toFixed(6);
                    const lng = evt.latLng.getLng().toFixed(6);

                    this.formItem.location = {
                        lat,
                        lng
                    }

                    this.setMarker(lat, lng);
                    this.isWatch = true;
                })
            }
        },

        // 设置点标记
        setMarker(lat = '', lng = '') {
            this.removeMarker();
            this.marker = new TMap.MultiMarker({
                map: this.map,  //指定地图容器
                styles: {
                    myStyle: new TMap.MarkerStyle({
                        width: 25,
                        height: 35,
                        anchor: {
                            x: 16,
                            y: 32
                        }
                    })
                },
                geometries: [
                    {
                        id: 1,
                        styleId: 'myStyle',
                        position: new TMap.LatLng(lat, lng)
                    }
                ]
            })
        },
        removeMarker() {
            if (this.marker) {
                this.marker.setMap(null);
                this.marker = null;
            }
        },
        getSecNavList(navs) {
            return this.$axios({
                url: '/api/secNav/goods/list',
                method: 'post',
                data: {
                    navs
                }
            });
        },
        resetSecNav(value, newValue, secNavs) {
            return this.$axios({
                url: '/api/secNav/goods/reset',
                method: 'post',
                data: {
                    value,
                    newValue,
                    secNavs
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        margin-top: 20px;

        .add-submit {
            width: 150px;
        }

        .add-radio {
            margin-right: 20px;
        }
    }
    .add-100 {
        width: 100px;
    }
    .add-120 {
        width: 120px;
    }
    .add-150 {
        width: 150px;
    }
    .add-210 {
        width: 210px;
    }
    .add-title {
        font-weight: 700;
    }
    .store-account {
        font-size: 16px;
    }
    .qrcode {
        width: 100px !important;
        height: 100px !important;
    }
    .qrcode-box {
        display: inline-block;
    }
    .ewm {
        width: 120px;
        height: 120px;
    }
    .qrcode-submit {
        display: block;
        // margin-left: 6px;
        // margin-top: 10px;
        margin: 10px auto 0;
    }

    .address-location {
        margin-top: 10px;
        display: flex;
    }
    // .address-location-input {
    //     width: 50%;
    // }
    // .address-location-input:nth-of-type(1) {
    //     margin-right: 5px;
    // }
    .address-location-input:nth-of-type(2) {
        margin-left: 10px;
    }
    .address-map {
        position: relative;
        margin-top: 10px;
    }
    #map {
        height: 500px;
    }
    .address-baidumap {
        width: 100%;
        height: 500px;
    }
    .map-search {
        position: absolute;
        left: 15px;
        top: 15px;
        width: 200px;
        height: 34px;
        line-height: 34px;
        padding: 0 5px;
        border-radius: 4px;
        border: 2px solid #50a2f0;
    }
    .mapsug {
        position: absolute;
        left: 15px;
        top: 50px;
        bottom: 10px;
        overflow: auto;
    }
    .tuijianren-openid {
        margin-left: 10px;
    }
    .zindex1,
    .zindex2,
    .zindex3 {
        position: relative;
        z-index: 1;
    }
    .zindex2 {
        z-index: 2;
    }
    .zindex3 {
        z-index: 3;
    }
    .tjybh {
        margin-left: 20px;
        margin-right: 5px;
    }
    #myCanvas {
        width: 430px;
        height: 500px;
    }
</style>
